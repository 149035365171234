:root {
  --text: #161616;
  --primary-background: #EDF4F9;
  --primary-accent: #F995CE;
  --primary-accent-darker: #F963B8;
  --primary-accent-text: #330A21;
  --secondary-background: #FFF2F5;
  --secondary-accent: #32FFC1;
  --component-background: #FFFFFF;
  --component-accent: #999999;
  --component-disabled: #E5E5E5;
}

.app {
  min-height: 100vh;
  height: 100%;
  background-color: var(--primary-background)
}
